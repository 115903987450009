const noTags = /<\/?[\w\s]*>|<.+[\W]>/g;

export const validation = {
  /* validation*/
  emailRules: [
    (value: any) => !!value || "Required",
    (value: any) => (value || "").length <= 50 || "Max 50 characters",
    (value: any) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return value ? pattern.test(value) || "Invalid email." : false;
    },
  ],
  nameRules: [
    (value?: string) => !!value || "Required.",
    (value?: string) => (value && value.trim().length >= 3) || "Min 3 characters",
    (value?: string) =>
      (value && value.trim().length <= 100) || `Max 100 characters`,
    (value?: string) => {
      let pattern = /[a-zA-Z\u0600-\u06FF]/g;
      return value ? pattern.test(value.trim()) || "Invalid Input!" : false;
    },
    (value?: string) => {
      return value ? !noTags.test(value.trim()) || "Invalid Input!" : false;
    },
    (value?: string) => {
      return value ? !noTags.test(value.trim()) || "Invalid Input!" : false;
    },
  ],

  required: [
    (value?: string) => !!value || "required",
    (value?: string) => {
      return value ? !noTags.test(value.trim()) || "Invalid Input!" : false;
    },
    (value?: string) => {
      return value ? !noTags.test(value.trim()) || "Invalid Input!" : false;
    },
  ],

  briefRules: [
    (value?: string) => !!value || "Required.",
    (value?: string) => (value && value.trim().length >= 3) || "Min 3 characters",
    (value?: string) =>
      (value && value.trim().length <= 4000) || `Max 4000 characters`,
    (value?: string) => {
      let pattern = /[a-zA-Z\u0600-\u06FF]/g;
      return value ? pattern.test(value.trim()) || "Invalid Input!" : false;
    },
    (value?: string) => {
      return value ? !noTags.test(value.trim()) || "Invalid Input!" : false;
    },
    (value?: string) => {
      return value ? !noTags.test(value.trim()) || "Invalid Input!" : false;
    },
  ],

};
