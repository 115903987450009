
























import Vue from "vue";
const axios = require("axios");
import * as validation from "../global/validation";

export default Vue.extend({
  name: "signUpForm",
  data: () => ({
    email: "",
    // validation
    Valid: true,
    validation: validation.validation,
    // handle response messages
    signUpLoading: false,
    success: false,
    error: false,
    response: false,
    text: "",
  }),

  methods: {
    signUp: async function () {
      let that = this;
      if ((this as any).$refs.applyForm.validate()) {
        that.signUpLoading = true;
        let obj = {
          email: this.email,
          name: "TBC website",
          subject: "sign up form",
          notMailer: true,
        };
        axios({
          method: "POST",
          url: "https://landlord-bl-staging.azurewebsites.net/api/sixBeesEmailsCollector/createEmail",
          data: obj,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response: any) => {
            if (response.data.ok) {
              that.response = true;
              that.error = false;
              that.success = true;
              that.text = "Done ... ";
              that.signUpLoading = false;
            } else {
              that.response = true;
              that.success = false;
              that.error = true;
              that.text = response.data.errorMessage;
              that.signUpLoading = false;
            }
          })
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        console.log((this as any).$refs.applyForm.validate());
        that.signUpLoading = false;
      }
    },
  },
});
