import Vue from "vue";
import signUpForm from "../../components/signUpForm.vue";



export default Vue.extend({
    name: "home",
    components: {
        signUpForm,
    },
    data: () => ({
        loadingCard: false,
        Schedule: false,
        loading: false,
        sendingReq: true,
        model: null,
    }),
    // watch: {
    //     sendingReq: {
    //         immediate: true,
    //         handler(newVal, oldVal) {
    //             this.sendingReq = this.loadingCard ? false : true;
    //         },
    //     },
    // },
    methods: {
        openSchedule() {
            this.Schedule = true;
            this.loadingCard = true
            setTimeout(() => (this.loadingCard = false), 3000)
        },

    },
});
