/* src/plugins/vuetify.js*/

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        lightColor: "#699DEE",
        subColor: "#EC6D1F",
        whiteColor: "#fff",
        greyColor: "#C7C7C7",
        darkColor: "#222222",
        calender:"#2A3B4D",

        greenColor: "#EC6D1F",
        lightBlue: "#699DEE",
        lightOrange: "#FDB846",
        darkOrange: "#F05C45",
        purpleColor: "#3D437E",
        lightPurple: "#8A74EA",
      },
    },
  },
  treeShake: true,
});



