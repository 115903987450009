import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import  axios from 'axios'
//import * as VueAxios from 'vue-axios'
import vuetify from "@/plugins/vuetify";


Vue.config.productionTip = false;

new Vue({
  router,
  //axios,
  //VueAxios,
  vuetify,
  render: function (h: any) {
    return h(App);
  },
}).$mount("#app");





