import Vue from "vue";
import VueRouter from "vue-router";

import home from "../views/home/home.vue";
import store from "../views/store/store.vue";
import contactUs from "../views/contactUs/contactUs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/store",
    name: "store",
    component: store,
  },
 
  {
    path: "/contactUs",
    name: "contactUs",
    component: contactUs,
  }
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
