import Vue from "vue";
import signUpForm from "../../components/signUpForm.vue";



export default Vue.extend({
  name: "store",
  components: {
    signUpForm,
  },
  data: () => ({
    lifeTrib: [
      "Self awareness",
      "Self-managment",
      "Social awareness",
      "social management",
      "Law of attraction",
      "Social etiquette",
      "Communication skills",
      "Setting and achieving your personal, career, and health objectives"]
  })
});
