<template>
  <div class="navBar" style="z-index: 99999999999999999999999999999999999999">
    <v-app-bar fixed class="py-0 elevation-4" height="80px" color="darkColor">
      <v-container class="px-0">
        <v-row align="center" justify="center" class="mx-0 px-0">
          <v-col sm="1">
            <v-toolbar-title>
              <v-img
                :contain="true"
                :src="require('../assets/imgs/TBCicon.png')"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-toolbar-title>
          </v-col>
          <v-col sm="11" class="d-none d-md-block">
            <v-tabs
              class="whiteColor--text"
              background-color="transparent"
              right
            >
              <v-tabs
                class="subColor--text"
                background-color="transparent"
                mobile-breakpoint="991"
                right
              ></v-tabs>
              <v-tabs-slider color="subColor"></v-tabs-slider>
              <v-tab
                :link="true"
                to="/"
                class="text-uppercase whiteColor--text"
                style="min-width: 100px"
              >
                Home
              </v-tab>
              <v-tab
                class="text-uppercase whiteColor--text"
                style="min-width: 100px"
                href="https://courses.tbc-me.com/"
              >
                Courses
              </v-tab>
              <v-tab
                :link="true"
                to="/contactUs"
                class="text-uppercase whiteColor--text"
                style="min-width: 125px"
              >
                Contact Us
              </v-tab>
              <!-- <v-btn class="rounded-pill subColor px-8 py-0 font-weight-bold ml-16 mt-2">Afiliate Login</v-btn>
              <v-tab :link="true" to="/contactUs" class="text-uppercase whiteColor--text">Login</v-tab> -->
            </v-tabs>
          </v-col>
          <v-col sm="11" class="d-block d-md-none float-right text-right">
            <v-app-bar-nav-icon
              class="greenColor--text elevation-5"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed top temporary color="darkColor">
      <v-list justify="center" align="center" nav dense>
        <v-list-item-group
          v-model="group"
          active-class="greenColor darkColor--text"
        >
          <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text text-decoration-none"
              ><router-link class="whiteColor--text" :to="{ name: 'home' }"
                >home</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text text-decoration-none"><router-link class="whiteColor--text" :to="{ name: 'store' }">Store</router-link></v-list-item-title>
          </v-list-item> -->

          <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text text-decoration-none"
              ><router-link class="whiteColor--text" :to="{ name: 'contactUs' }"
                >Contact Us</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text"><router-link class="whiteColor--text" :to="{ name: 'contactUs' }">Login</router-link></v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "navBar",
  components: {},
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.navBar {
  width: 100%;
  z-index: 99999999999999999999999999999999999999;
}
.navBar .v-btn > .v-btn__content .v-icon {
  color: #ec6d1f !important;
}
</style>
