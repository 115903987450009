import Vue from "vue";
import signUpForm from "../../components/signUpForm.vue";
const axios = require("axios");
import * as validation from "../../global/validation";

export default Vue.extend({
  name: "contactUs",
  components: {
    signUpForm,
  },
  data: () => ({
    // validation
    Valid: true,
    validation: validation.validation,
    // handle response messages
    contactLoading: false,
    success: false,
    error: false,
    response: false,
    text: "",
    // contact object
    conObj: {
      name: "",
      email: "",
      url: "",
      objective: "",
      storeEmail: false,
    },
  }),

  methods: {
    contactUs: async function () {
      let that = this;
      if ((this as any).$refs.contactForm.validate()) {
        that.contactLoading = true;
        Object.assign({}, this.conObj);
        // let obj = {
        //     email: this.conObj.email,
        //     name: "TBC website",
        //     subject: "Contact Us Form",
        //     content: `name: ${this.conObj.name} - email: ${this.conObj.email} - business URL: ${this.conObj.url} - objectives: ${this.conObj.objective} - store email: ${this.conObj.storeEmail}`,
        //     notMailer: false,
        // };
        let obj = {
          requestKey: "VvR9cNx7SmV3zG8BcxyKx8DfQYKCv4eBJVRv7C58",
          accessKey: "",
          messageTo: ["youssry.e@6bees.co", "info@tbc-me.com"],
          //messageTo: ["huda.h@6bees.co"],
          messageSubject: "Scheduled Meeting Details",
          messageBody: `<strong>name: </strong>${this.conObj.name}<br /><strong>email: </strong>${this.conObj.email}<br /><strong>business URL:</strong> ${this.conObj.url}<br /><strong>objectives: </strong>${this.conObj.objective}<br /><strong>store email: </strong>${this.conObj.storeEmail}`,
          messageCC: "",
          messageBCC: "",
          messageReplyTo: "",
        };
        axios({
          method: "POST",
          url: "https://landlord-bl-staging.azurewebsites.net/api/Mailer/SendEmail",
          data: obj,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response: any) => {
            if (response.data.ok) {
              that.response = true;
              that.error = false;
              that.success = true;
              that.text = "Done ... ";
              that.contactLoading = false;
            } else {
              that.response = true;
              that.success = false;
              that.error = true;
              that.text = response.data.errorMessage;
              that.contactLoading = false;
            }
          })
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        console.log((this as any).$refs.contactForm.validate());
        that.contactLoading = false;
      }
    },
  },
});
