<template>
  <v-app class="app overflow-hidden darkColor">
    <nav-bar></nav-bar>
    <router-view :workshops="workshops" />
    <footer-layout></footer-layout>
  </v-app>
</template>

<script>
/*@ is an alias to /src*/
import NavBar from "./components/navBar.vue";
import footerLayout from "./components/footerLayout.vue";

export default {
  name: "app",
  components: {
    NavBar,
    footerLayout,
  },
  data: () => ({
    pageLoaded: false,
    // workshops scroll
    options: {
      duration: 1000,
      offset: 0,
      easing: "easeInOutCubic",
    },
    workshops: {
      tribeSection: "mainSection",
    },
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        let that = this;
        if (this.$route.name == "workshops") {
          setTimeout(() => {
            that.$vuetify.goTo("." + that.workshops.tribeSection, {
              //container: ".box",
              duration: 500,
              offset: 0,
              easing: "easeInOutCubic",
            });
          }, 500);
        } else {
          this.$vuetify.goTo(".app", this.options);
          that.workshops.tribeSection = "mainSection";
        }
      },
    },
  },
  // mounted() {
  //   this.pageLoaded = true;
  // },
  created() {
    let that = this;
    that.$router.push("/");
    setTimeout(() => {
      that.pageLoaded = true;
    }, 6000);
  },
};
</script>

<style lang="scss">
//@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;700&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

$body-font-family: "Poppins", sans-serif !important;
.v-application {
  overflow: hidden !important;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0 !important;
}

.v-application,
.v-application .headline,
.v-application .title,
.v-application .subheading,
.v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6 {
  font-family: "Poppins", sans-serif !important;
}

/* global rules*/
* {
  font-family: "Poppins", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}
.poppins {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-size: 3.3rem;
  line-height: 1.3;
  font-family: "Poppins", sans-serif !important;
}

.container {
  max-width: 1050px !important;
  font-family: "Poppins", sans-serif !important;
}

.app {
  letter-spacing: 0 !important;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  @media (max-width: 991px) {
    .image {
      min-height: 550px;
    }
  }
}

.theme--light.v-tabs > .v-tabs-bar .v-tab--active {
  font-family: "Poppins", sans-serif;
  color: #ec6d1f !important;
  font-weight: bold;
  letter-spacing: 0 !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}
/* transation */
.fade-enter-active {
  animation: fade-in 1s ease forwards;
}
@keyframes fade-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
