<template>
  <div class="footerLayout darkColor py-3">
    <v-container class="fill-height">
      <v-row>
        <v-divider class="my-4 mx-5 greyColor"></v-divider>
      </v-row>

      <v-row class="fill-height" justify="center" align="center">
        <p class="whiteColor--text text-body-1 text-center">Copyright © 2022 The Business Clinic - ME - All Rights Reserved.</p>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
import * as validation from "@/global/validation.ts";
export default {
  name: "footerLayout",
  components: {},
  data: () => ({
    email: "",
    loading: false,
    // validation
    validation: validation.validation,
    Valid: false,
    newobj: {
      email: "",
      notMailer: true,
    },
    // snackbar
    error: false,
    errText: "",
    timeout: 2000,
    success: false,
  }),
  methods: {
    // addEmail() {
    //   if (this.$refs.emailForm.validate()) {
    //     axios({
    //       method: "POST",
    //       url: "https://localhost:44329/api/Competencies/PostCompetency",
    //       data: this.email,
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //       .then((response) => {
    //         console.log(response);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // },

    addEmail() {
      let docObj = Object.assign({}, this.newobj);
      if (this.$refs.emailForm.validate()) {
        this.loading = true;
        axios({
          method: "POST",
          url: "https://landlord-bl-staging.azurewebsites.net/api/sixBeesEmailsCollector/createEmail",
          data: docObj,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.data.ok) {
              this.success = true;
              this.error = false;
              this.loading = false;
            } else {
              this.success = false;
              this.loading = false;
              this.errText = response.data.errorMessage;
              console.log(response.data.errorMessage);
              this.error = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
